#header {
  height: 710px;
}
#header .header__backdrop {
  background: transparent linear-gradient(90deg, rgba(23, 102, 106, 0.8) 20%, rgba(71, 210, 216, 0.8) 100%) 0% 0% no-repeat padding-box;
}
#header .header__background-image {
  top: -40px;
  left: -80px;
}
@media (min-width: 992px) {
  #header .header__background-image {
    left: calc(45% - 1.5 * 340px);
  }
}
#header .header__overlay-image {
  visibility: hidden;
  bottom: 0;
  left: calc(45% + 340px * -0.5);
}
@media (min-width: 992px) {
  #header .header__overlay-image {
    visibility: visible;
  }
}
#header .app-buttons {
  filter: brightness(0) invert(0.9) drop-shadow(0px 0px 15px #47d2d8);
}
#header .header__contact {
  margin-top: 230px;
}
@media (min-width: 576px) {
  #header .header__contact {
    width: calc(368px + 6rem) !important;
  }
}

@media (max-width: 991.98px) {
  nav ul {
    padding-bottom: 100vh;
    background-color: #17666a;
  }
  nav ul .nav-item {
    position: relative;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  nav ul .nav-item:first-child {
    margin-top: 10px;
  }
  nav ul .nav-item:after {
    display: block;
    position: absolute;
    content: "";
    top: 8px;
    right: 1rem;
    background-image: url("../icons/arrow-down-white.svg");
    width: 16px;
    height: 16px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(-90deg);
  }
}
@media (max-width: 991.98px) and (min-width: 576px) {
  nav ul .nav-item:after {
    right: 3rem;
  }
}
nav .btn-epayco {
  background-color: transparent;
  color: #fff;
  outline: none;
  border: 0;
}
nav .btn-epayco:hover {
  color: #000;
}
nav .btn-epayco:focus {
  outline: none;
}

footer .footer__social-icons a {
  height: 48px;
  width: 48px;
  line-height: 3.2;
  border-radius: 50%;
  background: #547473;
  display: inline-block;
  text-align: center;
}
footer .footer__social-icons a:hover {
  background: #17666a;
}

.services {
  background: #f3fcfd;
}
.services .service-card {
  border-top-right-radius: 70px;
}
.services .service-card__image {
  height: 28vh;
  width: 100%;
  object-fit: cover;
  border-radius: 0 70px 0 70px;
}
.services .service-card p {
  line-height: 1.3;
}
.services .service-card h6 {
  margin-bottom: 0.75rem;
}
.services .service-card h5 {
  margin-bottom: 0.25rem;
}
@media (min-width: 768px) {
  .services .service-card {
    position: relative;
    border-radius: 0 0 0 70px;
  }
  .services .service-card__image {
    position: absolute;
    width: 32%;
    height: 100%;
    left: 0;
    z-index: 10;
  }
  .services .service-card__content {
    margin-left: 32%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.contact__form .input-group .form-control:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.contact__form .input-group .input-group-append > .btn {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.contact__form .form-response-container {
  height: 10px;
}
.contact__form .toast-container .toast {
  display: none;
  z-index: 99999 !important;
}
.contact__form .toast-container .toast .toast-header {
  background-color: #17666a;
}

.landing-view {
  /** ABOUT */
  /** BENEFITS */
  /** SERVICES */
  /** CLIENTS */
  /** ALIMENTS */
  /** FEATURED CLIENTS */
  /** APP */
  /** SPONSORS */
  /** CTA */
}
@media (min-width: 768px) {
  .landing-view .logo-environment {
    position: absolute;
  }
}
.landing-view .cta {
  height: 280px;
}
.landing-view .cta .cta__backdrop {
  background: transparent linear-gradient(90deg, rgba(23, 102, 106, 0.8) 20%, rgba(71, 210, 216, 0.8) 100%) 0% 0% no-repeat padding-box;
}
.landing-view .cta .cta__background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 991.98px) {
  .landing-view .cta .cta__background-image {
    left: 0;
  }
}

.section-title h2 {
  position: relative;
}
.section-title h2:before {
  content: "";
  position: absolute;
  border-top: 5px solid #47d2d8;
  border-radius: 5px;
  width: 8%;
  top: -20px;
  left: 46%;
}
@media (min-width: 768px) {
  .section-title .section-title-left:before {
    left: 0;
    width: 15%;
  }
}

.font-light {
  font-weight: 300 !important;
}

.font-regular {
  font-weight: 400 !important;
}

.font-semi-bold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

html, body {
  height: 100%;
  font-size: 16px;
}

.whatsapp-button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: #25d366;
  transition: transform 0.5s;
  font-size: 1.5rem;
  cursor: pointer;
}
.whatsapp-button:hover {
  transform: scale(1.2);
}